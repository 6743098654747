import Vue from 'vue'
import {FormBlockType} from 'components/form/types/form'
import {MultipartUploadCompletedData} from 'components/form/types/formDataTypes'
import {Route} from 'vue-router'
class BlockStaticVideo {
  protected vm: Vue
  protected formKey: string
  protected staticVideoBaseUrl: string
  protected mediaUploadScope: string
  protected mediaUploadBucket: string
  protected mediaUploadRegion: string
  protected env: EagleEnv

  constructor(vm: Vue, formKey: string, env: EagleEnv) {
    this.vm = vm
    this.formKey = formKey
    this.env = env
    this.mediaUploadScope = this.env.mediaUploadScope || ''
    this.mediaUploadBucket = this.env.mediaUploadBucket || ''
    this.mediaUploadRegion = this.env.mediaUploadRegion || ''
    this.staticVideoBaseUrl = this.env.staticVideoBaseUrl || ''
  }

  get() : FormBlockType {
    return {
      title: 'video.form_block.video',
      create: ({ formMode, formData }: FormInfoInterface) => {
        return formMode === 'update' && formData.type === this.vm.$videoConstants.TYPE_STATIC_VIDEO
      },
      data: {
        static_video_file_id: {
          label: 'video.data.type.file_id',
          type: 'readonly-content',
          copyable: true,
          route: (data: string, formInfo: FormInfoInterface) => ({
            name: 'file',
            query: { filter: `id=${data}` },
          })
        },
        static_video_path: {
          copyable: true,
          label: 'video.data.type.file',
          type: 'readonly-content',
        },
        // 一般上傳
        videoNormalUpload: {
          create: () => this.vm.env.staticVideoUploadMode === 'normal',
          label: 'data.file',
          type: 'file',
          maxQuantity: 1,
          onFileUploaded: (file: FileModel) => this.vm.onStaticVideoFileUploaded(file),
          hideFileList: true,
          customMaxFileSizeMb: 100,
        },
        // multipart上傳
        videoMultipartUpload: {
          label: 'action.upload',
          create: () => this.vm.env.staticVideoUploadMode === 'multipart',
          type: 'multipart-upload-file',
          validExtension: ['mp4', 'webm', 'avi', 'mov'],
          validFileType: ['video/'],
          acl: 'public-read',
          scope: this.mediaUploadScope,
          bucket: this.mediaUploadBucket,
          region: this.mediaUploadRegion,
          systemFileType: 'static-video',
          onUploadCompleted: (formData: AnyObject, data: MultipartUploadCompletedData) => this.vm.onStaticVideoMultipartUploadCompleted(formData, data),
          systemFileExtraFileBaseUrl: this.staticVideoBaseUrl,
          setFileObjectKey: (extension: string, fileUid: string) => {
            return `${this.mediaUploadScope}/static-video/${fileUid}.${extension}`
          },
          targetUid: (formData: AnyObject, route: Route) : string => {
            return `video-${route.params.target}`
          },
        },
      },
    }
  }
}

export default (vm: Vue, formKey: string, env: EagleEnv) => new BlockStaticVideo(vm, formKey, env)
