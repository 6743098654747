<template>
  <eagle-form
    :form-key="formKey"
  ></eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './videoForm'
import {MultipartUploadCompletedData} from 'components/form/types/formDataTypes'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'video-form',
    meta: {},
    pullVodConvertJobStatusInterval: null,
  }),
  beforeDestroy() {
    this.clearPullVodConvertJobStatusInterval()
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    async readApi(target) {
      return await this.$api.collection.videoApi.read(target)
    },
    afterFormInitFinished() {
      this.pullVodConvertJobStatus()
    },
    async createApi(formData) {
      return await this.$api.collection.videoApi.create(formData)
    },
    async updateApi(target, formData) {
      return await this.$api.collection.videoApi.update(target, formData)
    },
    async deleteApi(target) {
      return await this.$api.collection.videoApi.delete(target)
    },
    getFormConfig() {
      return formConfig
    },
    async onStaticVideoUploadCompleted(formData, data) {
      await this.$api.collection.videoApi.updateStaticVideoData(formData.id, {
        path: data.fileObjectKey,
        file_id: data.fileModel.id
      })
      this.actions.initForm()
    },
    async onStaticVideoMultipartUploadCompleted(file) {
      await this.$api.collection.videoApi.updateStaticVideoData(this.formData.id, {
        path: file.path,
        file_id: file.id
      })
      this.$store.dispatch(`form/${this.formKey}/setDataColumn`, { key: 'static_video_path', value: file.path })
      this.$store.dispatch(`form/${this.formKey}/setDataColumn`, { key: 'static_video_file_id', value: file.id })
    },
    async onStreamingVideoUploadCompleted(formData, data) {
      try {
        const result = await this.$api.collection.vodConvertJobApi.create({
          video_id: formData.id,
          s3_file_path: data.fileObjectKey,
          source_size: data.file.size,
          origin_filename: data.file.name,
        })
      } catch (error) {
        console.warn(error)
      } finally {
        await this.actions.initForm()
        this.pullVodConvertJobStatus()
      }
    },
    async pullVodConvertJobStatus() {
      if(!this.formData) return
      if(!this.formData.vod_convert_job_id) return
      this.clearPullVodConvertJobStatusInterval()
      await this.$nextTick()
      this.pullVodConvertJobStatusAction()
      this.pullVodConvertJobStatusInterval = window.setInterval(() => {
        this.pullVodConvertJobStatusAction()
      }, 10000)
    },
    clearPullVodConvertJobStatusInterval() {
      this.pullVodConvertJobStatusInterval = clearInterval(this.pullVodConvertJobStatusInterval)
      this.pullVodConvertJobStatusInterval = null
    },
    async pullVodConvertJobStatusAction() {
      if(!this.formData) {
        return this.clearPullVodConvertJobStatusInterval()
      }
      if(!this.formData.vod_convert_job) {
        return this.clearPullVodConvertJobStatusInterval()
      }

      if(!this.$eagleMedia.vodConvertJobShouldPullStatus(this.formData.vod_convert_job)) {
        return this.clearPullVodConvertJobStatusInterval()
      }
      const vodConvertJobId = this.formData.vod_convert_job_id

      this.$store.dispatch('loading/progress')
      try {
        const result = await this.$api.collection.vodConvertJobApi.updateAwsData(vodConvertJobId)
        this.$store.dispatch(`form/${this.formKey}/setDataColumn`, {
          key: 'vod_convert_job',
          value: result,
        })
      } catch (error) {
        console.warn(error)
      } finally {
        this.$store.dispatch('loading/closeProgress')
      }
    },
  },
  computed: {
    actions() {
      return this.$store.getters[`form/${this.formKey}/actions`]
    },
    env() {
      return this.$store.getters['base/eagleEnv']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
