import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
import {MultipartUploadCompletedData} from 'components/form/types/formDataTypes'
import blockYoutube from 'modules/video/views/videoForm/blockYoutube'
import blockStaticVideo from 'modules/video/views/videoForm/blockStaticVideo'
declare module 'vue/types/vue' {
  interface Vue {
    env: EagleEnv,
    onStaticVideoFileUploaded: (file: FileModel) => void
    onStaticVideoUploadCompleted: (formData: AnyObject, data: MultipartUploadCompletedData) => void,
    onStaticVideoMultipartUploadCompleted: (formData: AnyObject, data: MultipartUploadCompletedData) => void,
    onStreamingVideoUploadCompleted: (formData: AnyObject, data: MultipartUploadCompletedData) => void,
  }
}


class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      title: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.video', route: { name: 'video' } },
      ],
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_PAGE']),
      listRoute: () => ({ name: 'video' }),
      afterCreateRoute: result => ({
        name: 'video-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.video',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            title: {
              required: true,
              label: 'data.title',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            code: {
              required: true,
              randomUid: true,
              label: 'data.code',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            keywords: {
              label: 'data.keywords',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            photo: {
              label: 'data.photo',
              type: 'photo',
              photoType: 'video',
              photoManager: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            brief: {
              label: 'page.data.brief',
              type: 'textarea',
              grid: { xl: 12, lg: 12, md: 12 },
              maxlength: 300,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            content: {
              label: 'data.content',
              type: 'html',
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },

        // 影片設定(youtube)
        blockYoutube(this.vm, this.formKey).get(),

        // 影片設定(靜態影片)
        blockStaticVideo(this.vm, this.formKey, this.vm.env).get(),

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],

      customDataAction: {
        preview: {
          label: 'action.preview',
          icon: 'fa fa-film',
          callback: (formInfo: FormInfoInterface) => this.vm?.$previewVideo(formInfo.formData),
        }
      },
    }
  }
}

export default new formConfig()
