import Vue from 'vue'
import {FormBlockType} from 'components/form/types/form'
class BlockYoutube {
  protected vm: Vue
  protected formKey: string
  constructor(vm: Vue, formKey: string) {
    this.vm = vm
    this.formKey = formKey
  }

  get() : FormBlockType {
    return {
      title: 'video.form_block.video',
      create: ({ formMode, formData }: FormInfoInterface) => {
        return formMode === 'update' && formData.type === this.vm.$videoConstants.TYPE_YOUTUBE
      },
      data: {
        youtube_url: {
          label: 'video.data.youtube_url',
          grid: { xl: 12, lg: 12, md: 12 },
          create: (formInfo: FormInfoInterface) => {
            if(!formInfo.formData) return false
            return formInfo.formData.type === this.vm.$videoConstants.TYPE_YOUTUBE
          },
          maxlength: 150,
          thumbnail: (formInfo: FormInfoInterface, data: any) => {
            const youtubeUid = this.vm.$helper.getYoutubeUid(data)
            return this.vm.$helper.getYoutubeImage(youtubeUid)
          },
        },
      },
    }
  }
}

export default (vm: Vue, formKey: string) => new BlockYoutube(vm, formKey)
